import {
    ADD_POST,
    DELETE_POST,
    GET_POSTS,
    GET_POST,
    POST_ERROR,
    UPDATE_LIKES,
    ADD_COMMENT,
    REMOVE_COMMENT
} from '../actions/types';



const initialState = {
    posts: [],
    post: null,
    loading: true,
    error: {}
}

const post = function(state = initialState, action) {
    const { type, payload } = action;

    switch(type){
        case GET_POSTS:
            return {
                ...state,
                posts: payload,
                loading: false
            }
        case GET_POST:
            return {
                ...state,
                post: payload,
                loading: false
            }
        case ADD_POST:
            return {
                ...state,
                posts: [payload, ...state.posts],
                loading: false
            }
        case DELETE_POST:
            return {
                ...state, 
                posts: state.posts.filter(post => post._id !== payload._id),
                loading: false
            }
        case POST_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            };
        case UPDATE_LIKES:
            return {
                ...state,
                posts: state.posts.map(post => 
                    post._id === payload.id ? { ...post, likes: payload.likes} : post
                ),
                post: {...state.post, likes: payload.likes},
                loading: false
            };
        case ADD_COMMENT:
            return {
                ...state,
                post: { ...state.post, comments: payload }
            };
        case REMOVE_COMMENT:
            return {
                ...state,
                post: { 
                    ...state.post, 
                    comments: state.post.comments.filter(comment => comment._id !== payload.id) 
                },
                loading: false
            };
        default:
            return state;
    }
}

export default post;